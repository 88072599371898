import { BsPatchCheckFill } from "react-icons/bs";
import "./services.css";

const Services = () => {
  return (
    <section id='experience'>
      <h2>Technical Skills</h2>
      
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Web Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>HTML5</h4>
        
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>CSS3</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Tailwind</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>React.js</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Next.js</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>NPM</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Node.js</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Express.js</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>MongoDB</h4>
              </div>
             </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Python</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Git</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>PostgresSQL</h4>
              </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}

        <div className="experience__backend">
        <h3>Cloud DevOps</h3>
          <div className="experience__content">
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Cloud computing platforms (AWS, Azure, GCP)</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Infrastructure as Code (IaC) tools (Terraform, CloudFormation, Ansible)</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Continuous Integration and Continuous Deployment (CI/CD)</h4>
              </div>
             </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Automation scripting (Python, Bash, Jenkins)</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Containerization and orchestration (Docker, Kubernetes)</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Linux and Windows System Administration</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Networking and Security Fundamentals</h4>
              </div>
            </article>
            <article className='experience__details'>
              {/* <BsPatchCheckFill className='experience__details-icon' /> */}
              <div>
                <h4>•	Code Version Control (Git)</h4>
              </div>
            </article>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
