import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'


const data = [
    {id: 1, icon: <TbBooks/>, title: 'Projects', desc: '20+ Completed'},
    {id: 2, icon: <FaAward/>, title: 'Experience', desc: '10+ Stacks used'},
    
    ]



    export default data;